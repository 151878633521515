import React from "react"
import styles from "./sidebar.module.css"

const sidebar = props => {
  return (
    <aside className={`${styles.aside} ${(props.MobileView) ? styles.mobileNav : ''}`}>
      <div className={styles.content}>
        <strong>Inhaltsverzeichnis</strong>
        <ol>
          {props.Items.map((data, i) => {
            return (
            <li key={i} className={props.HighlightItem === data ? styles.visible : ''}>
              <a href={`#${data}`}>{data}</a>
            </li>
            )
          })}
        </ol>
      </div>
        
      
    </aside>
  )
}

export default sidebar
