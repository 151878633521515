import React from "react"
import { Helmet } from "react-helmet"
import styles from "./header.module.css"

const header = props => {
  return (
    <div id={props.Anker} className={styles.headerContainer}>
      <Helmet htmlAttributes={{ lang: "de" }} defer={false}>
        <link
          href="https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className={styles.projectDetails}>
        <div>
          <p className={styles.projectDetailsTitle}>Ressort</p>
          <p className={styles.projectDetailsAnswer}>{props.Ressort}</p>
        </div>
        <div>
          <p className={styles.projectDetailsTitle}>Technologie</p>
          <p className={styles.projectDetailsAnswer}>{props.Tech}</p>
        </div>
        <div>
          <p className={styles.projectDetailsTitle}>Kunde</p>
          <p className={styles.projectDetailsAnswer}>
            {props.CostumerLong}
          </p>
        </div>
        <div>
          <p className={styles.projectDetailsTitle}>Webseite</p>
          <p className={styles.projectDetailsAnswer}>{props.WebSite}</p>
        </div>
      </div>
      <div className={styles.projectDescription}>
  <p className={styles.projectDescriptionName}>{props.CostumerShort}</p>
        <h1 className={styles.projectDescriptionBanner}>
          {props.title}
        </h1>
        <p className={styles.projectDescriptionContent}>
          {props.teaserText}
        </p>
      </div>
    </div>
  )
}

export default header
